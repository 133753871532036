import { clsx, type ClassValue } from "clsx";
import { PgSelect } from "drizzle-orm/pg-core";
import { twMerge } from "tailwind-merge";
import { isValidUrl } from "./urls";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const response = await fetch(input, { ...init, cache: "no-store" });

  return response.json();
}

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const truncate = (str: string, num: number) => {
  if (!str) return "";
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const getBlurDataURL = async (url: string | null) => {
  if (!url) {
    return "data:image/webp;base64,AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=";
  }
  try {
    const response = await fetch(
      `https://wsrv.nl/?url=${url}&w=50&h=50&blur=5`,
    );
    const buffer = await response.arrayBuffer();
    const base64 = Buffer.from(buffer).toString("base64");

    return `data:image/png;base64,${base64}`;
  } catch (error) {
    return "data:image/webp;base64,AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=";
  }
};

export const placeholderBlurhash =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAoJJREFUWEfFl4lu4zAMRO3cx/9/au6reMaOdkxTTl0grQFCRoqaT+SQotq2bV9N8rRt28xms87m83l553eZ/9vr9Wpkz+ezkT0ej+6dv1X81AFw7M4FBACPVn2c1Z3zLgDeJwHgeLFYdAARYioAEAKJEG2WAjl3gCwNYymQQ9b7/V4spmIAwO6Wy2VnAMikBWlDURBELf8CuN1uHQSrPwMAHK5WqwFELQ01AIXdAa7XawfAb3p6AOwK5+v1ugAoEq4FRSFLgavfQ49jAGQpAE5wjgGCeRrGdBArwHOPcwFcLpcGU1X0IsBuN5tNgYhaiFFwHTiAwq8I+O5xfj6fOz38K+X/fYAdb7fbAgFAjIJ6Aav3AYlQ6nfnDoDz0+lUxNiLALvf7XaDNGQ6GANQBKR85V27B4D3QQRw7hGIYlQKWGM79hSweyCUe1blXhEAogfABwHAXAcqSYkxCtHLUK3XBajSc4Dj8dilAeiSAgD2+30BAEKV4GKcAuDqB4TdYwBgPQByCgApUBoE4EJUGvxUjF3Q69/zLw3g/HA45ABKgdIQu+JPIyDnisCfAxAFNFM0EFNQ64gfS0EUoQP8ighrZSjn3oziZEQpauyKbfjbZchHUL/3AS/Dd30gAkxuRACgfO+EWQW8qwI1o+wseNuKcQiESjALvwNoMI0TcRzD4lFcPYwIM+JTF5x6HOs8yI7jeB5oKhpMRFH9UwaSCDB2Jmg4rc6E2TT0biIaG0rQhNqyhpHBcayTTSXH6vcDL7/sdqRK8LkwTsU499E8vRcAojHcZ4AxABdilgrp4lsXk8oVqgwh7+6H3phqd8J0Kk4vbx/+sZqCD/vNLya/5dT9fAH8g1WdNGgwbQAAAABJRU5ErkJggg==";

export const statsBadgeThemes = {
  red: {
    colors: {
      icon: "#ff4d4d",
      text: "#ff4d4d",
      border: "#ff4d4d",
      background: {
        dark: "transparent",
        light: "transparent",
      },
      backgroundHover: {
        dark: "transparent",
        light: "transparent",
      },
    },
    opacities: {
      icon: 1,
      text: 1,
      border: 1,
      background: 0.15,
      backgroundHover: 0.15,
    },
  },
  blue: {
    colors: {
      icon: "#0099ff",
      text: "#0099ff",
      border: "#0099ff",
      background: {
        dark: "transparent",
        light: "transparent",
      },
      backgroundHover: {
        dark: "transparent",
        light: "transparent",
      },
    },
    opacities: {
      icon: 1,
      text: 1,
      border: 1,
      background: 0.15,
      backgroundHover: 0.15,
    },
  },
  green: {
    colors: {
      icon: "#22c55e",
      text: "#22c55e",
      border: "#22c55e",
      background: {
        dark: "transparent",
        light: "transparent",
      },
      backgroundHover: {
        dark: "transparent",
        light: "transparent",
      },
    },
    opacities: {
      icon: 1,
      text: 1,
      border: 1,
      background: 0.15,
      backgroundHover: 0.15,
    },
  },
  purple: {
    colors: {
      icon: "#a855f7",
      text: "#a855f7",
      border: "#a855f7",
      background: {
        dark: "transparent",
        light: "transparent",
      },
      backgroundHover: {
        dark: "transparent",
        light: "transparent",
      },
    },
    opacities: {
      icon: 1,
      text: 1,
      border: 1,
      background: 0.15,
      backgroundHover: 0.15,
    },
  },
  orange: {
    colors: {
      icon: "#f97316",
      text: "#f97316",
      border: "#f97316",
      background: {
        dark: "transparent",
        light: "transparent",
      },
      backgroundHover: {
        dark: "transparent",
        light: "transparent",
      },
    },
    opacities: {
      icon: 1,
      text: 1,
      border: 1,
      background: 0.15,
      backgroundHover: 0.15,
    },
  },
  gradient: {
    colors: {
      icon: "#f97316",
      text: "#f97316",
      border: "#f97316",
      background: {
        dark: "transparent",
        light: "transparent",
      },
      backgroundHover: {
        dark: "transparent",
        light: "transparent",
      },
    },
    opacities: {
      icon: 1,
      text: 1,
      border: 0.3,
      background: 1,
      backgroundHover: 0.2,
    },
  },
  glass: {
    colors: {
      icon: "currentColor",
      text: "currentColor",
      border: "currentColor",
      background: {
        dark: "transparent",
        light: "transparent",
      },
      backgroundHover: {
        dark: "transparent",
        light: "transparent",
      },
    },
    opacities: {
      icon: 0.9,
      text: 0.9,
      border: 0.3,
      background: 1,
      backgroundHover: 1,
    },
  },
} as const;

// Function to interpolate between two flame configurations
export const interpolateFlameConfig = (
  growthPercentage: number,
  particleFlames: Record<string, any>,
) => {
  // Define the breakpoints from the particleFlames object
  const breakpoints = Object.keys(particleFlames)
    .map(Number)
    .sort((a, b) => a - b);

  // Find the two breakpoints to interpolate between
  let lowerBreakpoint = breakpoints[0];
  let upperBreakpoint = breakpoints[breakpoints.length - 1];

  for (let i = 0; i < breakpoints.length - 1; i++) {
    if (
      growthPercentage >= breakpoints[i] &&
      growthPercentage < breakpoints[i + 1]
    ) {
      lowerBreakpoint = breakpoints[i];
      upperBreakpoint = breakpoints[i + 1];
      break;
    }
  }

  // If growth is higher than the highest breakpoint, use the highest config
  if (growthPercentage >= upperBreakpoint) {
    return { ...particleFlames[upperBreakpoint] };
  }

  // Calculate interpolation factor
  const factor =
    (growthPercentage - lowerBreakpoint) / (upperBreakpoint - lowerBreakpoint);

  // Interpolate between the two configurations
  const lowerConfig = particleFlames[lowerBreakpoint];
  const upperConfig = particleFlames[upperBreakpoint];

  const result: Record<string, any> = {};

  // Interpolate each property
  for (const key in lowerConfig) {
    if (typeof lowerConfig[key] === "number") {
      result[key] =
        lowerConfig[key] + factor * (upperConfig[key] - lowerConfig[key]);
    } else {
      result[key] = lowerConfig[key];
    }
  }

  return result;
};

export const particleFlames = {
  0: {
    quantity: 15,
    vx: 0,
    vy: -0.2,
    staticity: 1,
    ease: 100,
    fireIntensity: 0.3,
    particleDecay: 0.01,
    yOffset: -30,
    xOffset: 0,
    radius: 1,
    baseSpeed: 0.4,
  },
  50: {
    quantity: 35,
    vx: 0,
    vy: -0.4,
    staticity: 1,
    ease: 40,
    fireIntensity: 2,
    particleDecay: 0.01,
    yOffset: -10,
    xOffset: 0,
    radius: 1.2,
    baseSpeed: 0.4,
  },
  100: {
    quantity: 50,
    vx: 0,
    vy: -0.4,
    staticity: 1,
    ease: 40,
    fireIntensity: 2,
    particleDecay: 0.01,
    yOffset: -10,
    xOffset: 0,
    radius: 1.2,
    baseSpeed: 0.4,
  },
  200: {
    quantity: 70,
    vx: 0,
    vy: -0.4,
    staticity: 1,
    ease: 40,
    fireIntensity: 2,
    particleDecay: 0.01,
    yOffset: -10,
    xOffset: 0,
    radius: 1.2,
    baseSpeed: 0.4,
  },
  500: {
    quantity: 200,
    vx: 0,
    vy: -1,
    staticity: 1,
    ease: 40,
    fireIntensity: 2,
    particleDecay: 0.001,
    yOffset: 10,
    xOffset: 0,
    radius: 2,
    baseSpeed: 0.4,
  },
  1000: {
    quantity: 300,
    vx: 0,
    vy: -1.5,
    staticity: 1,
    ease: 30,
    fireIntensity: 3,
    particleDecay: 0.0005,
    yOffset: 10,
    xOffset: 0,
    radius: 2.5,
    baseSpeed: 0.6,
  },
};

export type StatsBadgeTheme = keyof typeof statsBadgeThemes;

export const toDateString = (date: Date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const random = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export function withLimit<T extends PgSelect>(qb: T, limit: number) {
  return qb.limit(limit);
}

type NonNullableProps<T> = {
  [P in keyof T]: null extends T[P] ? never : P;
}[keyof T];

export function stripUndefined<T>(obj: T): Pick<T, NonNullableProps<T>> {
  const result = {} as T;
  for (const key in obj) if (obj[key] !== undefined) result[key] = obj[key];
  return result;
}

export const getDomainWithoutWWW = (url: string) => {
  if (!url) return null;

  if (isValidUrl(url)) {
    // Remove trailing slashes and get hostname without www
    return new URL(url).hostname.replace(/^www\./, "");
  }
  try {
    if (url.includes(".") && !url.includes(" ")) {
      return new URL(`https://${url}`).hostname.replace(/^www\./, "");
    }
  } catch (e) {
    return null;
  }
};

export async function hashStringSHA256(str: string) {
  // Encode the string into bytes
  const encoder = new TextEncoder();
  const data = encoder.encode(str);

  // Hash the data with SHA-256
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);

  // Convert the buffer to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export type ErrorCode = "NOT_FOUND" | "UNAUTHORIZED" | "UNAUTHENTICATED";

export type ConvexErrorResponse = {
  code: ErrorCode;
  message: string;
};

export type ConvexError = {
  error?: ConvexErrorResponse;
};

export type ErrorHandlerResult = {
  hasError: boolean;
  message?: string;
  redirect?: string;
};

// Default redirect paths
const defaultRedirectMap: Record<ErrorCode, string> = {
  NOT_FOUND: "/projects",
  UNAUTHORIZED: "/select-org",
  UNAUTHENTICATED: "/login",
};

export const handleCommonErrors = (
  error: ConvexErrorResponse | undefined,
  redirectMap?: Partial<Record<ErrorCode, string>>,
): ErrorHandlerResult => {
  if (!error) return { hasError: false };

  const redirects = {
    ...defaultRedirectMap,
    ...(redirectMap || {}),
  };

  return {
    hasError: true,
    message: error.message,
    redirect: redirects[error.code],
  };
};

export const isErrorResponse = (response: any): response is ConvexError => {
  return response && "error" in response && response.error !== undefined;
};

export const checkCommonErrors = (
  response: any,
  redirectMap?: Partial<Record<ErrorCode, string>>,
): ErrorHandlerResult => {
  if (isErrorResponse(response)) {
    return handleCommonErrors(response.error, redirectMap);
  }
  return { hasError: false };
};
