import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import Image from "next/image";
import { twMerge } from "tailwind-merge";
const buttonVariants = cva("inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50", {
  variants: {
    variant: {
      default: " whitespace-nowrap bg-primary text-lg text-primary-foreground shadow-2xl shadow-indigo-600/50 rounded-lg ",
      destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
      secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "text-primary underline-offset-4 hover:underline",
      "btn-primary": " whitespace-nowrap hover:text-primary-foreground dark:bg-gradient-to-t dark:from-[#24232C] dark:to-[#100F18] dark:text-primary-foreground border hover:border-primary dark:border-[#464553] dark:hover:bg-accent hover:bg-primary dark:hover:border-muted-foreground text-lg font-normal",
      "btn-secondary": "whitespace-nowrap dark:text-primary-foreground text-lg font-normal",
      transparent: "bg-transparent text-white hover:bg-white/20",
      primary: "bg-sky-700 text-primary-foreground hover:bg-sky-700/90",
      primarysecond: " whitespace-nowrap hover:text-primary-foreground dark:bg-gradient-to-t dark:from-[#24232C] dark:to-[#100F18] dark:text-primary-foreground border hover:border-primary dark:border-[#464553] dark:hover:bg-accent hover:bg-primary dark:hover:border-muted-foreground text-lg font-normal",
      // debugging : fix down varient for darkmode
      grey: "bg-neutral-200 dark:bg-neutral-800 text-secondary-foreground hover:bg-neutral-300 dark:hover:bg-neutral-900",
      blue: "bg-indigo-700 text-white hover:bg-indigo-800/80",
      blueSecondary: "text-white hover:bg-indigo-900/80",
      white: "bg-secondary text-secondary-foreground hover:bg-zinc-100 select-none",
      ai: "bg-gradient-to-r from-red-500 to-purple-500 text-white hover:bg-ai/90"
    },
    size: {
      default: "h-10 px-4 py-2",
      xsm: "h-6 rounded-md px-3",
      sm: "h-9 rounded-md px-3",
      lg: "h-11 rounded-md px-8",
      icon: "h-10 w-10",
      inline: "h-auto px-2 py-1.5 text-sm"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: any;
  logoStyles?: string;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  icon,
  logoStyles,
  size,
  children,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  if (asChild) {
    return <Slot className={cn(buttonVariants({
      variant,
      size,
      className
    }))} ref={ref} {...props}></Slot>;
  }
  return <button className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props}>
        {icon && <Image src={icon} alt="icon" className={(twMerge("mr-2"), logoStyles)} width={30} />}
        {children}
      </button>;
});
Button.displayName = "Button";
export { Button, buttonVariants };