export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidHttpsUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === "https:";
  } catch (e) {
    return false;
  }
};

export const isValidDomain = (domain: string): boolean => {
  // Regular expression for domain validation
  // Matches format: subdomain(optional).domain.tld
  // TLD must be 2-63 characters
  const domainRegex =
    /^([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;
  return domainRegex.test(domain);
};

export const validateDomainInput = (
  input: string,
): { isValid: boolean; message: string } => {
  // Check if user is trying to enter a protocol
  if (input.includes("://") || input.includes(":")) {
    return {
      isValid: false,
      message: "Please enter only the domain name without http:// or https://",
    };
  }

  // If empty, it's valid (for optional fields)
  if (!input.trim()) {
    return { isValid: true, message: "" };
  }

  // Check if it's a valid domain
  if (!isValidDomain(input)) {
    return {
      isValid: false,
      message:
        "Please enter a valid domain (e.g., example.com or sub.example.com)",
    };
  }

  return { isValid: true, message: "" };
};

export const ensureHttps = (domain: string): string => {
  if (!domain) return "";

  // Remove any existing protocol
  const cleanDomain = domain.replace(/^(https?:\/\/)/, "");

  // Add https:// to the clean domain
  return `https://${cleanDomain}`;
};

export const isValidHttpsUrlWithDomain = (
  url: string,
): { isValid: boolean; message: string } => {
  try {
    const parsedUrl = new URL(url);

    if (parsedUrl.protocol !== "https:") {
      return { isValid: false, message: "URL must start with https://" };
    }

    if (!isValidDomain(parsedUrl.hostname)) {
      return {
        isValid: false,
        message: "Please enter a valid domain (e.g., example.com)",
      };
    }

    return { isValid: true, message: "" };
  } catch (e) {
    return {
      isValid: false,
      message: "Please enter a valid HTTPS URL (e.g., https://example.com)",
    };
  }
};

export const getUrlFromString = (str: string) => {
  if (isValidUrl(str)) return str;
  try {
    if (str.includes(".") && !str.includes(" ")) {
      return new URL(`https://${str}`).toString();
    }
  } catch (_) {}
  return str;
};

export const getUrlFromStringIfValid = (str: string) => {
  if (isValidUrl(str)) return str;
  try {
    if (str.includes(".") && !str.includes(" ")) {
      return new URL(`https://${str}`).toString();
    }
  } catch (_) {}
  return null;
};

export const getSearchParams = (url: string) => {
  // Create a params object
  let params = {} as Record<string, string>;

  new URL(url).searchParams.forEach(function (val, key) {
    params[key] = val;
  });

  return params;
};

export const getSearchParamsWithArray = (url: string) => {
  let params = {} as Record<string, string | string[]>;

  new URL(url).searchParams.forEach(function (val, key) {
    if (key in params) {
      const param = params[key];
      Array.isArray(param) ? param.push(val) : (params[key] = [param, val]);
    } else {
      params[key] = val;
    }
  });

  return params;
};

export const getParamsFromURL = (url: string) => {
  if (!url) return {};
  try {
    const params = new URL(url).searchParams;
    const paramsObj: Record<string, string> = {};
    for (const [key, value] of params.entries()) {
      if (value && value !== "") {
        paramsObj[key] = value;
      }
    }
    return paramsObj;
  } catch (e) {
    return {};
  }
};

export const UTMTags = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "ref",
] as const;

export const constructURLFromUTMParams = (
  url: string,
  utmParams: Record<string, string>,
) => {
  if (!url) return "";
  try {
    const newURL = new URL(url);
    for (const [key, value] of Object.entries(utmParams)) {
      if (value === "") {
        newURL.searchParams.delete(key);
      } else {
        newURL.searchParams.set(key, value);
      }
    }
    return newURL.toString();
  } catch (e) {
    return "";
  }
};

export const paramsMetadata = [
  { display: "UTM Source", key: "utm_source", examples: "twitter, facebook" },
  { display: "UTM Medium", key: "utm_medium", examples: "social, email" },
  { display: "UTM Campaign", key: "utm_campaign", examples: "summer_sale" },
  { display: "UTM Term", key: "utm_term", examples: "blue_shoes" },
  { display: "UTM Content", key: "utm_content", examples: "logolink" },
  { display: "Referral (ref)", key: "ref", examples: "twitter, facebook" },
];

export const getUrlWithoutUTMParams = (url: string) => {
  try {
    const newURL = new URL(url);
    paramsMetadata.forEach((param) => newURL.searchParams.delete(param.key));
    return newURL.toString();
  } catch (e) {
    return url;
  }
};

export const getPrettyUrl = (url?: string | null) => {
  if (!url) return "";
  // remove protocol (http/https) and www.
  // also remove trailing slash
  return url
    .replace(/(^\w+:|^)\/\//, "")
    .replace("www.", "")
    .replace(/\/$/, "");
};

export const createHref = (
  href: string,
  domain: string,
  // any params, doesn't have to be all of them
  utmParams?: Partial<Record<(typeof UTMTags)[number], string>>,
) => {
  if (domain === "dub.co") return href;
  const url = new URL(href.startsWith("/") ? `https://dub.co${href}` : href);
  if (utmParams) {
    Object.entries(utmParams).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  }
  return url.toString();
};
